<template>
  <!-- 拣货任务 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="入库单号">
              <el-input v-model="form.trayId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货单号">
              <el-input v-model="form.receiptId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号">
              <el-input v-model="form.orderId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库类型">
              <el-select v-model="form.depositType" :placeholder="$t('page.selectPlaceholder')">
                <el-option v-for="item in getDictionariesOpt('VWMS_DEPOSIT_TYPE')" :key="item.val" :value="item.val" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库">
              <el-select v-model="form.logicWarehouseCode" filterable clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.warehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="入库日期">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                value-format="yyyy-MM-dd "
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="mb-2">
          <el-button type="primary" @click="visible = true">导入入库单</el-button>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="trayId" label="入库单号" align="center">
        <template slot-scope="scope"><router-link :to="{ append:true, path: 'details', query: {trayId: scope.row.trayId, tableSuffix: scope.row.tableSuffix, trayData: JSON.stringify(scope.row)}}" style="color:#1890ff;">{{ scope.row.trayId }}</router-link></template>
      </el-table-column>
      <el-table-column prop="receiptId" label="收货单号" align="center">
        <template slot-scope="scope"><router-link :to="{ append:true, path: '../receipt-manage/details', query: {receiptId: scope.row.receiptId, tableSuffix: scope.row.tableSuffix, receiptData: JSON.stringify(scope.row)}}" style="color:#1890ff;">{{ scope.row.receiptId }}</router-link></template>
      </el-table-column>
      <el-table-column prop="orderId" label="关联单号" align="center" />
      <el-table-column prop="transportId" label="运输单号" align="center">
        <template slot-scope="scope">{{ scope.row.transportId }}</template>
      </el-table-column>
      <el-table-column prop="logicWarehouseName" label="仓库" align="center" />
      <el-table-column prop="depositType" label="入库类型" align="center">
        <template slot-scope="scope">{{ getDepositLabel(scope.row.depositType) }}</template>
      </el-table-column>
      <el-table-column prop="actualPairs" label="入库数量" align="center" />
      <el-table-column prop="inboundTime" label="入库时间" align="center" />
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">{{ getTrayStatusLabel(scope.row.status) }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        :label="$t('page.operate')"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 查看 -->
          <span>
            <el-button type="text" size="small" @click="goToDetails(scope.row)">查看</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="update" />
    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="visible"
      width="600px"
      @close="closeDialog"
    >
      <el-form ref="importForm" :model="importForm" label-width="8em" :rules="rules">
        <el-form-item label="服务商" prop="platformCode">
          <el-select v-model="importForm.platformCode" :placeholder="$t('page.selectPlaceholder')">
            <el-option v-for="item in getDictionariesOpt('VWMS_SERVICE_PROVIDER')" :key="item.val" :value="item.val + '&&' + item.label" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="导入类型" prop="importType">
          <el-radio-group v-model="importForm.importType">
            <el-radio label="STANDARD_IMPORT_CONFIG">标准入库单</el-radio>
            <el-radio label="RETURN_IMPORT_CONFIG">退货入库单</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <!-- <el-upload
            ref="uploadForm"
            action=""
            class="el-button padding-none"
            :http-request="uploadFile"
            :show-file-list="false"
            :file-list="fileList"
            :auto-upload="false"
            :on-change="fileChange"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload> -->
          <el-upload
            ref="upload"
            :headers="uploadHeaders"
            :before-upload="beforeAvatarUpload"
            :on-success="handleUploadSuccess"
            :on-remove="handleFileRemove"
            :on-progress="uploadFileProcess"
            :on-change="changeFile"
            :action="action"
            :auto-upload="false"
            :file-list="fileList"
            drag
            multiple
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div slot="tip" class="el-upload__tip">Tip：只能上传xlsx、xls或csv文件,且不超过500kb</div>

          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="visible = false">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="handleUpdata('form')">确 认</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { queryWarehouseList, pageTrayTask, collectTaskTrayDetailImport } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
import Paging from '@/components/Paging'
export default {
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      action: process.env.VUE_APP_UPLOAD_API,
      visible: false,
      form: {
        orderId: '',
        depositType: '',
        trayId: '',
        logicWarehouseCode: '',
        date: [],
        receiptId: ''
      },
      importForm: {
        importType: '',
        platformCode: '',
        siteCode: '',
        shopCode: ''
      },
      warehouseList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      showRow: false, // 切换显示input
      tableLoading: false,
      tableData: [
      ],
      fileList: [],
      rules: {
        importType: [{ required: true, message: '请选择导入类型', trigger: ['blur', 'change'] }],
        platformCode: [{ required: true, message: '请选择服务商', trigger: ['blur', 'change'] }]

      }
    }
  },
  computed: {
    queryParams() {
      const [startCreateTime = '', endCreateTime = ''] = this.form.date || []
      const { orderId, depositType, trayId, logicWarehouseCode, receiptId } = this.form
      const { size, current } = this.pager
      return Object.assign({}, { size, current, startCreateTime: startCreateTime ? startCreateTime + '00:00:00' : startCreateTime, endCreateTime: endCreateTime ? endCreateTime + '23:59:59' : endCreateTime, receiptId, orderId, depositType, trayId, logicWarehouseCode })
    }
  },

  mounted() {
    this._pageTrayTask()
    this._queryWarehouseList()
  },
  methods: {
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseList = datas
    },
    queryClick() { // 点击查询获取信息
      this.pager.current = 1
      this._pageTrayTask(1)
    },
    resetClick(val) { // 点击重置清空文本框信息
      Object.assign(this.form, this.$options.data.call(this).form)
      this._pageTrayTask(1)
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    async _pageTrayTask(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await pageTrayTask(this.queryParams)
        this.pager = pager
        this.tableData = records
        this.tableLoading = false
      } finally {
        this.tableLoading = false
      }
    },
    update(val) {
      this.pager = val
      this._pageTrayTask()
    },
    // 入库管理详情
    goToDetails(row) {
      this.$router.push({
        path: 'details',
        append: 'true',
        query: { 'trayId': row.trayId, 'tableSuffix': row.tableSuffix, trayData: JSON.stringify(row) }
      })
    },
    beforeAvatarUpload(file) {
      const name = file.name.split('.')
      // const isLt500K = file.size / 1024 < 500
      const isApk = name[name.length - 1] === 'xlsx' || name[name.length - 1] === 'xls'
      const isFile = !this.$refs.upload.clearFiles()
      // if (!isApk || !isLt500K) {
      //   this.$message({
      //     dangerouslyUseHTMLString: true,
      //     type: 'error',
      //     message: '只能上传xlsx、xls或csv文件,且不超过500kb'
      //   })
      // }
      if (!isFile) {
        this.$message({
          type: 'error',
          message: '只能上传一个文件'
        })
      }
      return isApk && isFile
    },
    uploadFileProcess(event, file, fileList) {
      console.log(event, file, fileList)
    },
    handleUploadSuccess(res) {
      const { datas } = res
      this.form.versionUrl = datas
    },
    handleFileRemove() {
      this.form.versionUrl = ''
    },
    changeFile(file, fileList) {
      console.log(file)
      console.log(file.raw)
      console.log(this.fileList[0])
      this.fileList = fileList.length > 0 ? [file.raw] : []
    },
    clearFiles() {
      this.importForm.platformCode = ''
      this.importForm.importType = ''
      this.importForm.siteCode = ''
      this.importForm.shopCode = ''
      this.$refs.upload.clearFiles()
    },
    handleUpdata() {
      console.log(this.fileList)
      const { importForm: { platformCode }} = this
      this.$refs['importForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        console.log(platformCode)
        if (!this.fileList[0]) {
          this.$message({
            type: 'warning',
            message: '请先选文件上传'
          })
          return
        }
        const arr = platformCode.split('&&') || []
        this.$confirm('当前导入的是：' + (arr[1] || '') + '/' + (this.importForm.importType === 'STANDARD_IMPORT_CONFIG' ? '标准入库单' : '退货入库单') + '单，确认导入？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const formDate = new FormData()
          formDate.append('file', this.fileList[0])
          formDate.append('importType', this.importForm.importType)
          formDate.append('platformCode', arr[0] || '')
          formDate.append('siteCode', this.importForm.siteCode)
          formDate.append('shopCode', this.importForm.shopCode)
          this._collectTaskTrayDetailImport(formDate)
        }).catch(() => {
          this.clearFiles()
          this.fileList = []
          console.log(this.fileList)
        })
      })
    },

    async _collectTaskTrayDetailImport(data) {
      try {
        const { code, msg } = await collectTaskTrayDetailImport(data)
        if (code === 0) {
          this.visible = false
          this.$message.success(msg)
          this.clearFiles()
          this._pageTrayTask()
        } else {
          this.visible = false
          this.$message.success(msg)
          this.clearFiles()
          this._pageTrayTask()
        }
      } catch (err) {
        console.log(err)
        this.clearFiles()
      }
    },
    closeDialog() {
      this.visible = false
      this.clearFiles()
    }
  }
}
</script>

